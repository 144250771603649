import React from "react";
import "./Redes.css";

class Redes extends React.Component {
  renderRedes = () => {
    return (
      <div className="redes">
        <a
          target="blank"
          href="https://api.whatsapp.com/send/?phone=%2B56975196533&text=¡Hola!+ Deseo+reservar+un+salto+Tandem+en+Skydive+Pichilemu.&app_absent=0"
          role="button"
          title="Whatsapp"
        >
          <img className="btn-wsp" src={require("../../images/wsp.png")} alt="Whatsapp"/>
        </a>
      </div>
    );
  };

  render() {
    return <React.Fragment>{this.renderRedes()}</React.Fragment>;
  }
}

export default Redes;
