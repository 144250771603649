import React, { Component } from "react";
import { ReactPhotoCollage } from "react-photo-collage";
import "./Gallery.css";

import image1 from "../../images/img1.jpg";
import image2 from "../../images/img2.jpg";
import image3 from "../../images/img3.jpg";
import image4 from "../../images/img4.jpg";
import image5 from "../../images/img5.jpg";
import image6 from "../../images/img6.jpg";

class Gallery extends Component {
  render() {
    const setting = {
      width: "100%",
      height: ["200px", "200px"],
      layout: [3, 3],
      photos: [
        { source: image1 },
        { source: image2 },
        { source: image3 },
        { source: image4 },
        { source: image5 },
        { source: image6 },
      ],
      showNumOfRemainingPhotos: true,
    };
    return (
      <React.Fragment>
        <div id="gallery" className="about-area ptb-100">
          <div className="bar"></div>
          <div className="row gallery-sky">
            <div className="col-md-12">
              <div className="saas-section-title title">
                <h2>Galería Skydive</h2>
                <p>
                  Imagenes referenciales de Skydive Aloha y Skydive Puerto
                  Escondido
                </p>
              </div>
              <div className="row sky">
                <div className="container">
                  <div className="col-sm">
                    <div className="title">
                      <ReactPhotoCollage className="image" {...setting} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Gallery;
