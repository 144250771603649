import React, { Component} from "react";
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import config from "../../config";
import "./Administrationsky.css";
import "./Loginsky.css";

const DEFAULT_STATE = {
  tickets: [],
  events: {},
  adminPassword: false,
  isError: false,
  isAuth: false,
  operation: false, // PAY o DEL
}

class Administration extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  handleShow = (index) => {
    const {tickets} = this.state;
    tickets[index].isShow = true;
    this.setState({tickets});
  }
  handleHide = (index) => {
    const {tickets} = this.state;
    tickets[index].isShow = false;
    this.setState({tickets});
  };

  format_events(events) {
    const response = {};
    function get_event(value) {
      response[value.event] = value;
    }
    events.forEach(get_event);
    return response;
  }
  sort_tickets(tickets) {
    const data = [].concat(tickets)
      .sort((a, b) => a.event > b.event ? 1 : -1)
    return data;
  }
  updateTickets() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'pass': this.state.adminPassword
      })
    };
    fetch(config.url.api + "/event/ticket/find", requestOptions)
      .then(response => response.json())
      .then(response  => {
        this.setState({
          isAuth: response.code !== 401,
          isError: response.code === 0 ? false : response.message,
          tickets: this.sort_tickets(response.payload),
          events: this.format_events(response.events)
        });
      });
  }

  handleLogin = (evt) => {
    evt.preventDefault();
    this.updateTickets();
  }

  handleLogout = (evt) => {
    evt.preventDefault();
    this.setState(DEFAULT_STATE);
  }

  handlePayment = (evt) => {
    evt.preventDefault();
    this.setState({operation: 'PAY'});
  }

  handleDelete = (evt) => {
    evt.preventDefault();
    this.setState({operation: 'DEL'});
  }

  update(event, email) {
    let action = false;
    if (this.state.operation == 'PAY') {
      action = 'payment';
    } else
    if (this.state.operation == 'DEL') {
      action = 'delete';
    } else {
      this.setState({isError: true, message: 'Operación no válida! Favor contactar con Soporte'});
      return;
    }
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'pass': this.state.adminPassword,
        'event': event,
        'email': email,
        'action': action
      })
    };
    fetch(config.url.api + "/event/ticket", requestOptions)
      .then(response => response.json())
      .then(response  => {
        this.setState({
          isAuth: response.code !== 401,
          isError: response.code === 0 ? false : response.message,
          operation: false
        });
        this.updateTickets();
      });
  }

  handleConfirm = (event, email) => {
    this.update(event, email);
  }

  handleCancel = (evt) => {
    evt.preventDefault();
    this.setState({operation: false});
  }

  render_login() {
    return (
      <section id="login" className="about-area ptb-100 login">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="saas-section-title login">
                <h2>Admin Skydivepichilemu</h2>
              </div>
              <form className="form-login" onSubmit={this.handleLogin}>
                <div className="form-group">
                  <div className="saas-section-title login">
                    <h3>Contraseña</h3>
                  </div>
                  <div className="row">
                    <div className="col">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        onChange={(e) =>
                          this.setState({ adminPassword: e.target.value })
                        }
                        value={this.state.adminPassword}
                      />
                    </div>          
                  </div>
                </div>
                <button type="submit" className="btn btn-primary login">
                  Ingresar
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    )
  }

  render_modal(event, email, payment, index) {
    return (
      <div>
        <div className="principal">
          <div className="secundario">
          { 
            <Button color="success" onClick={() => this.handleShow(index)}>Actualizar estado </Button>
          }           
          </div>
        </div>
        <Modal isOpen={this.state.tickets[index].isShow} backdrop={false} aria-hidden="true">
          <ModalHeader>
            Actualizar estado 
            <Button className="close" onClick={() => this.handleHide(index)}>
              <span aria-hidden="true">&times;</span>
            </Button>
          </ModalHeader>
          <ModalBody>
            {
              this.state.operation ?
              <div className="alert alert-warning alert-dismissible fade show alerta" role="alert">
                <strong>Acabas de actualizar el estado</strong> <p>Deseas continuar?</p>  
                <button className="btn btn-success btn-sm admin" type="button" onClick={() => this.handleConfirm(event, email)}>
                  continuar
                </button>
                <button className="btn btn-danger btn-sm admin" type="button" onClick={this.handleCancel}>
                  cancelar
                </button>
                <button type="button" className="close" onClick={() => this.handleHide(index)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              :
              <div div className="container">
                {
                  !payment &&
                  <button type="button" className="btn btn-success btn-lg btn-block" onClick={this.handlePayment}>Confirmar Pago</button>
                }
                <button type="button" className="btn btn-danger btn-lg btn-block" onClick={this.handleDelete}>Eliminar</button>
              </div>
            }
          </ModalBody>
        </Modal>
      </div>
    );
  }

  render_navadmin() {
    return (
      <div className="container">
        <nav className="navbar navbar-light">
          <a className="navbar-brand adminsky">
            <img src={require("../../images/logo.PNG")} alt="black-logo" />
          </a>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="btn btn-primary" onClick={this.handleLogout}>
                Cerrar sesi&oacute;n
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }

  render_table() {
    const { events, tickets } = this.state;
    const btntype = (status) => {
      if (!status) {
        return "btn-warning"
      } else {
        return "btn-success"
      }
    };
    return (
      <div className="container-fluid">
        <table className="table table-bordered ">
          <thead className="table-bordered table-hover thead-dark ">
            <tr>
              <th scope="col">Tipo de Salto</th>
              <th scope="col">Día</th>
              <th scope="col">Fecha</th>
              <th scope="col">Hora</th>
              <th scope="col">Nombre</th>
              <th scope="col">Apellido</th>
              <th scope="col">Correo</th>
              <th scope="col">Teléfono</th>
              <th scope="col">Estado</th>
              <th scope="col">Actualizar estado</th>
            </tr>
          </thead>
          <tbody>
          {
            tickets.map((item, index) => (
              <tr key={index} style={{backgroundColor: events[item.event].color}}>
                <td className="text-center">{events[item.event].event_type}</td>
                <td className="text-center">{events[item.event].date.slice(0,7)}</td>
                <td className="text-center">{events[item.event].date.slice(7,18)}</td>
                <td className="text-center">{events[item.event].date.slice(18,24)}</td>
                <td className="text-center">{item.name}</td>
                <td className="text-center">{item.last_name}</td>
                <td className="text-center">{item.email}</td>
                <td className="text-center">{item.phone}</td>
                <td>
                    <button
                      type="button"
                      disabled={true}
                      className={"btn " + (btntype(item.payment))}
                    >
                      {item.payment ? item.payment : "Reservado"}
                    </button>
                  </td>
                  <td>{this.render_modal(item.event, item.email, item.payment, index)}</td>
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <section
        id="administration"
        className="about-area ptb-100 administration"
      >
        <div>
        {
          this.state.isAuth ?
          <div>
            {this.render_navadmin()}
            {this.render_table()}
          </div>
          :
          this.render_login()
        }
      </div>
      </section>
    );
  }
}

export default Administration;
