import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from './pages/Home';
import Administration from './pages/Administration';
import NotFound from './pages/NotFound';

export default ({ childProps }) =>
  <Switch>
    <Route path="/" exact component={Home} props={childProps} />
    <Route path="/administracion" exact component={Administration} props={childProps} />
    <Route component={NotFound} />
  </Switch>;
