const local = {
  ga_analytics: '',
  url: {
    api: "https://api.labs.skydivepichilemu.cl"
  },
  ReCAPTCHA: '6Lfwiu0eAAAAANXsFcTn172Eqz4V3k9OqMoc6ks-',
  ReCAPTCHA2: '6LeG9X4fAAAAALKT2zFLBnLxfS4tPZaAErPUq5o6',
};

const dev = {
  ga_analytics: '',
  url: {
    api: "https://api.labs.skydivepichilemu.cl"
  },
  ReCAPTCHA: '6Lfwiu0eAAAAANXsFcTn172Eqz4V3k9OqMoc6ks-',
  ReCAPTCHA2: '6LeG9X4fAAAAALKT2zFLBnLxfS4tPZaAErPUq5o6',
};

const prod = {
  ga_analytics: 'UA-',
  url: {
    api: "https://api.skydivepichilemu.cl"
  },
  ReCAPTCHA: '6Lfwiu0eAAAAANXsFcTn172Eqz4V3k9OqMoc6ks-',
  ReCAPTCHA2: '6LeG9X4fAAAAALKT2zFLBnLxfS4tPZaAErPUq5o6',
};

const config = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.trim() === 'production'
  ? prod
  : process.env.REACT_APP_ENV === 'development'
  ? dev
  : local;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
