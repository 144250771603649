import React, { Component } from "react";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import "./Banner.css";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "animate.css";

class Banner extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="home" className="main-banner ban">
        <img className="banner-img" src={require("../../images/skydivepichilemu.jpg")} />
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-12">
                <div className="text-animation">
                  <h1 className="animated animate__bounceInDown first-text-animation">
                    Skydive Pichilemu
                  </h1>
                  <h2 className="animated zoomIn second-text-animation">
                    #VuelaSinLimites
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-6 third-text">
                <div className="text-animation">
                  <p className="animated zoomIn third-text-animation">
                    Entra al viento a 200 km/h <br/>
                    a 12.000 feet (3.658 metros de altura)
                  </p>
                  <AnchorLink
                    className="btn btn-primary animated animate__backInLeft four-text-animation "
                    type="button"
                    href="#reserva"
                  >
                    Reserva aquí
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Banner;
