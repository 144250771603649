import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Navbar extends Component {
    render() {
        return (
            <React.Fragment>
            <nav id="navbar" className="navbar navbar-expand-lg navbar-style-three navbar-light bg-light">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                    </Link>
                </div>
            </nav>
            </React.Fragment>
        );
    }
}

export default Navbar;
