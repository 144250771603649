import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="footer-area bg-fffcf4">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="single-footer-widget">
              <a target="blank" href="https://skydivealoha.cl/">
              <img src={require("../../images/aloha.png")} alt="" />
              </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single-footer-widget">   
              <a target="blank" href="https://www.instagram.com/club_aereo_pichilemu/?hl=es">
              <img src={require("../../images/club-aereo.jpeg")} alt="" />
              </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single-footer-widget">     
              <a target="blank" href="https://www.instagram.com/surflyers/">
              <img src={require("../../images/sur.png")} alt="" />
              </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single-footer-widget">
              <a target="blank" href="https://www.instagram.com/camara.de.turismo/">          
              <img src={require("../../images/camara-turismo.png")} alt="" />
              </a>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-7">
                <p className="footer-p">
                  <i className="far fa-copyright"></i> 2022{" "}
                  <Link to="/">SkydivePichilemu</Link>. Todos los derechos
                  reservados
                </p>
              </div>
              <div className="col-lg-5 col-md-5">
                <p className="footer-p" style={{ textAlign: "center" }}>
                  Sitio desarrollado por{" "}
                  <a
                    href="https://www.neokode.cl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Neokode
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
