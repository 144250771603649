import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from "../../config";
import "./Services.css";
import "./Terms.css";
import ReCAPTCHA from "react-google-recaptcha";
import Modals from "./Modal";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      name: "",
      last_name: "",
      phone: "",
      email: "",
      eventType: "",
      event: "",
      key: "",
      isChecked: false,
      isErrorName: false,
      isErrorLastname: false,
      isErrorPhone: false,
      isErrorEmail: false,
      isErrorEventType: false,
      isErrorSelectEvent: false,
      isLoading: false,
      isError: false,
      isSuccess: false,
      isErrorCaptcha: false,
      message: "",
      show: false,
      isLoadingEvent: false
    };
  }

  handleShow = (data) => {
    this.setState({ show: data });
  }

  handleChecked = (event) => {
    this.setState({ isChecked: event.target.checked });
    return this.state.isChecked;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  async get_events(data) {
    const response = await fetch(config.url.api + "/event/find", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });
    return response.json();
  }
  load_events = (event_type) => {
    this.get_events({ event_type: event_type })
      .then(response => {
        if (response.code === 0) {
          this.setState({ events: response.payload, isLoadingEvent: false })
        } else {
          alert('Error al cargar los eventos');
          this.setState({ isLoadingEvent: false })
        }
      })
  }
  handleChangeEventType = (event) => {
    this.setState({
      eventType: event.target.value,
      isLoadingEvent: true
    });
    this.load_events(event.target.value)
  }
  validate() {
    var errors = {};
    const textValidate = /^[a-zA-ZÀ-ÿ\s]{3,40}$/ //Solo Letras y espacios, pueden llevar acentos.
    const numberValidate = /^\d{9}$/ // Se valida que solo tenga 9 numeros.
    const emailValidate = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ // Se valida el correo.

    errors.isErrorName = !textValidate.test(this.state.name);
    errors.isErrorLastname = !textValidate.test(this.state.last_name);
    errors.isErrorPhone = !numberValidate.test(this.state.phone);
    errors.isErrorEmail = !emailValidate.test(this.state.email);
    errors.isErrorEventType = this.state.eventType === "";
    errors.isErrorSelectEvent = this.state.events === [];
    errors.isErrorCaptcha = this.state.key.length < 64;
    errors.hasError =
      errors.isErrorName ||
      errors.isErrorLastname ||
      errors.isErrorPhone ||
      errors.isErrorEmail ||
      errors.isErrorEventType ||
      errors.isErrorSelectEvent ||
      errors.isErrorCaptcha;
    return errors;
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.hasError) {
      errors.hasError = undefined;
      this.setState({ ...errors });
      return;
    }
    this.setState({ ...errors, isLoading: true });
    try {
      this.ticket({
        event: this.state.event,
        name: this.state.name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
        key: this.state.key
      });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  };
  ticket(data) {
    return fetch(config.url.api + "/event/ticket", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      return res.json();
    }).then((response) => {
      if (response.code === 0) {
        this.setState({
          isLoading: false,
          isError: false,
          isSuccess: true,
          message: "",
          name: "",
          last_name: "",
          email: "",
          phone: "",
          eventType: "",
          event: "",
          events: [],
          isChecked: false
        });
      } else {
        this.setState({ isLoading: false, isError: true, isSuccess: false, message: response.message });
      }
    });
  }

  handleClose = () => {
    this.setState({ isSuccess: false, isError: false });
  }

  handleChangeRC = (value) => {
    this.setState({ key: value, isEmailError: false, isKeyError: false });
  };

  render_Terms() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 condition">
            <div className="md-form mb-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  checked={this.state.isChecked}
                  onChange={this.handleChecked}
                  type="checkbox"
                  disabled={this.state.isLoading}
                  value={this.state.isChecked}
                  id="check"
                />
                <label className="form-check-label" name="Terms">
                  HE LEÍDO Y ACEPTO LOS
                  {
                    <strong>
                      <a
                        type="button"
                        onClick={() => this.handleShow(true)}
                      >
                        TÉRMINOS Y CONDICIONES
                      </a>
                    </strong>
                  }
                </label>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.show} size="lg" fullscreen fade={false} centered={true} scrollable={true}  >
          <ModalHeader className="modal-header mod">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => this.handleShow(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
            TÉRMINOS Y CONDICIONES

          </ModalHeader>
          <ModalBody className="modal-body">
            <div className="box">
              <p><strong>T&Eacute;RMINOS Y CONDICIONES</strong></p>
              <p><span>Por todo lo descrito en los p&aacute;rrafos siguientes, declaro estar de acuerdo y renunciar en este acto de manera expresa e irrevocable, al ejercicio de cualquier acci&oacute;n de demanda en contra de Skydive Pichilemu, Skydive Aloha y Surflyers, los Instructores T&aacute;ndem, la empresa Skydive Aloha, los due&ntilde;os de la aeronave y sus trabajadores o colaboradores, por da&ntilde;os a la propiedad, posibles lesiones o incluso mi muerte. Declaro haber le&iacute;do completamente el documento y estoy de acuerdo con las condiciones y acuerdos para realizar el salto T&aacute;ndem o salto Deportivo</span></p>
              <ul>
                <li ><span > Declaro, comprendo y acepto los t&eacute;rminos descritos.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><span >Entre&nbsp;</span><strong>Skydive Pichilemu</strong><span >, y </span><strong>el Usuario</strong><span > que suscribe y acepta el presente instrumento, se ha acordado celebrar el presente acuerdo de uso del sitio web www.skydivepichilemu.cl (&ldquo;Acuerdo&rdquo; o &ldquo;Acuerdo de Uso&rdquo;), el que se regir&aacute; por los T&eacute;rminos y Condiciones que a continuaci&oacute;n se indican. Las transacciones que se efect&uacute;en a trav&eacute;s de www.skydivepichilemu.cl se sujetan adem&aacute;s a la Legislaci&oacute;n chilena vigente, en particular a la Ley 19.496 sobre protecci&oacute;n de los Derechos de los Consumidores y la Ley 19.628 sobre Protecci&oacute;n de la Vida Privada. Es requisito para comprar en el sitio la aceptaci&oacute;n de los T&eacute;rminos y Condiciones descritos a continuaci&oacute;n.</span></p>
              <p>&nbsp;</p>
              <p><strong>PRIMERO: GENERALIDADES &ndash; OBJETO DEL ACUERDO</strong></p>
              <p><span >El presente documento regula los t&eacute;rminos y condiciones bajo los cuales Ud. tiene derecho a acceder y usar los servicios del sitio Web: www.skydivepichilemu.cl, &nbsp;y de cualquier informaci&oacute;n, texto, video u otro material comunicado en el sitio web.</span></p>
              <p><span >En este sitio Web podr&aacute; usar, sin costo, el software y las aplicaciones para equipos m&oacute;viles que le permitan navegar, visitar, comparar y si lo desea, adquirir los servicios que se exhiben aqu&iacute;.</span></p>
              <p><span >Le recomendamos que lea detenidamente estas Condiciones e imprima o guarde una copia de las mismas en la unidad de disco local para su informaci&oacute;n.</span></p>
              <p><span >Estos T&eacute;rminos y Condiciones ser&aacute;n aplicados y se entender&aacute;n incorporados en cada uno de los contratos que celebre con Skydive Pichilemu por medio de este sitio web.</span></p>
              <p><span >El uso de este sitio web, la aplicaci&oacute;n de estos T&eacute;rminos y Condiciones, los actos que ejecute y los contratos que celebre por medio de este sitio web, se encuentran sujetos y sometidos a las leyes de la Rep&uacute;blica de Chile y en especial a la ley 19.496 de protecci&oacute;n de los derechos de los consumidores, refundido en el D.F.L. N&deg; 3 de 31 de mayo de 2021.</span></p>
              <p><span >Skydive Pichilemu, por lo tanto, aplicar&aacute; estrictamente todos los beneficios, garant&iacute;as y derechos reconocidos en favor de los consumidores en la ley 19.496. Adem&aacute;s, Skydive Pichilemu adhiere en todas sus partes al C&oacute;digo de Buenas Pr&aacute;cticas para el Comercio Electr&oacute;nico de la C&aacute;mara de Comercio de Santiago.</span></p>
              <p>&nbsp;</p>
              <p><strong>SEGUNDO: DECLARACIONES.</strong></p>
              <p><span >El Usuario declara, conoce y acepta:</span></p>
              <ol>
                <ol>
                  <li ><span >Que es mayor de edad, la edad se deber&aacute; acreditar con c&eacute;dula de identidad o documentaci&oacute;n de identificaci&oacute;n similar emitido en el extranjero, lo cual lo habilita para acceder a este sitio.</span></li>
                  <li ><span >Que conoce la naturaleza tecnol&oacute;gica del Sitio y de su contenido y que, por tanto, est&aacute; natural e inherentemente expuesto a interrupciones y/o a indisponibilidades, que no necesariamente est&aacute;n bajo el control y responsabilidad de Skydive Pichilemu.</span></li>
                  <li ><span >Excepcionalmente podr&aacute;n acceder a este servicio los mayores de 15 a&ntilde;os que cuenten con autorizaci&oacute;n notarial de ambos padres/tutor.</span></li>
                  <li ><span >El peso M&aacute;ximo de quien vaya a utilizar los servicios de Skydive Pichilemu debe ser de 100 kilos. Sin embargo, el IMC no debe superar los 30 y la morfolog&iacute;a del aspirante tambi&eacute;n son factores determinantes, siendo necesario mantener una proporci&oacute;n entre el peso y la altura que sea razonable. Para estos efectos se tendr&aacute; en cuenta la informaci&oacute;n facilitada en el momento de la reserva. Si la informaci&oacute;n no resultase cierta, se suspender&aacute; el salto y no se har&aacute; devoluci&oacute;n de la reserva.</span></li>
                  <li ><span >El consumidor debe tener salud compatible con la actividad a realizar. Toda persona que realice Salto T&aacute;ndem o curso de paracaidismo deber&aacute; firmar previamente una declaraci&oacute;n de condici&oacute;n F&iacute;sica.</span></li>
                </ol>
              </ol>
              <p>&nbsp;</p>
              <ul>
                <li><strong><span >La pr&aacute;ctica del paracaidismo requiere de una condici&oacute;n f&iacute;sica que sea compatible con dicha actividad deportiva. Por lo mismo existen ciertas consideraciones a contemplar: No deben existir lesiones ni estar bajo tratamiento por enfermedades o adicciones. Se Proh&iacute;be saltar haciendo uso de medicamentos con contraindicaci&oacute;n medica relativa al desarrollo de una actividad deportiva. Se encuentra prohibido saltar bajo la influencia del alcohol o drogas. </span>No se debe haber ejercido la pr&aacute;ctica de buceo en las &uacute;ltimas 24 horas.</strong></li>
              </ul>
              <p>&nbsp;</p>
              <ol>
                <li ><span >Si el d&iacute;a de la reserva no se ha presentado la documentaci&oacute;n necesaria, podr&iacute;a perder el derecho a realizar el salto, adem&aacute;s de acarrear los gastos derivados de la reserva.</span></li>
              </ol>
              <p>&nbsp;</p>
              <p><strong>TERCERO: COMUNICACIONES</strong></p>
              <p><span >Para la comunicaci&oacute;n de cualquier presentaci&oacute;n, consulta o reclamo a prop&oacute;sito del uso de este sitio, o los contratos que en &eacute;l se lleven a cabo, Skydive Pichilemu designa como representante especial a don Juan Manuel Quezada Navarrete, disponible en el correo juanma.q@skydivepichilemu.com; y en el tel&eacute;fono de Servicio al Cliente +56 9 75196533, domiciliado para estos efectos en la calle El Parque 240, comuna de Pichilemu, ciudad de Pichilemu.</span></p>
              <p><span >Skydive Pichilemu se obliga a que en caso de enviarle informaci&oacute;n publicitaria o promocional por correo electr&oacute;nico, &eacute;sta contendr&aacute; al menos la siguiente informaci&oacute;n:</span></p>
              <ol>
                <li ><span >a) Identificaci&oacute;n del mensaje, que lo haga reconocible como publicidad o comunicaci&oacute;n promocional, en el campo de asunto del mensaje.</span></li>
                <li ><span >b) Existencia del derecho del consumidor o usuario a solicitar el cese de env&iacute;os de publicidad de ese tipo por Skydive Pichilemu y la obligaci&oacute;n de Skydive Pichilemu de suspender esos env&iacute;os.</span></li>
                <li ><span >c) Un procedimiento simple y eficaz para que el consumidor pueda hacer esa solicitud, indicando una direcci&oacute;n electr&oacute;nica para estos efectos.</span></li>
                <li ><span >d) La Identificaci&oacute;n del anunciante con su denominaci&oacute;n comercial.</span></li>
              </ol>
              <p><span >Skydive Pichilemu cesar&aacute; el env&iacute;o de los mensajes publicitarios o promocionales por correo electr&oacute;nico u otros medios de comunicaci&oacute;n individual equivalentes, a toda persona que hubiere solicitado expresamente la suspensi&oacute;n de esos env&iacute;os.</span></p>
              <p>&nbsp;</p>
              <p><strong>CUARTO: SOBRE SKYDIVE PICHILEMU Y SALTO T&Aacute;NDEM.</strong></p>
              <p><span >El presente documento contiene informaci&oacute;n importante que debe conocer antes del Salto T&aacute;ndem.</span></p>
              <p><span >El Salto T&aacute;ndem no es un Curso de Paracaidismo. Se trata simplemente de un salto en que no existe pr&aacute;cticamente fase previa ni formaci&oacute;n y el pasajero lo &uacute;nico que tiene que hacer es disfrutar de la ca&iacute;da libre.</span></p>
              <p><span >La decisi&oacute;n final en cuanto a las aptitudes y actitudes del cliente para poder realizar la actividad con seguridad, ser&aacute; tomada por el centro de paracaidismo a trav&eacute;s de sus instructores, debiendo respetarse en todo momento la normativa vigente del centro.</span></p>
              <p>&nbsp;</p>
              <p><strong>QUINTO: DERECHO DE ADMISI&Oacute;N.</strong></p>
              <p><span >Skydive Pichilemu se reserva el derecho de admisi&oacute;n a cualquiera de sus actividades.</span></p>
              <p>&nbsp;</p>
              <p><strong>SEXTO: POL&Iacute;TICAS DE RESERVA SALTO TANDEM</strong></p>
              <p><span >Para Realizar la compra de tu Salto debes:</span></p>
              <ul>
                <li ><span >Ingresar a https://skydivepichilemu.cl All&iacute; podr&aacute;s pagar la totalidad del servicio, existiendo diversos medios o formas de pago, busca el que m&aacute;s te acomode. No olvides completar todos los datos solicitados en la p&aacute;gina. Lo anterior, sin perjuicio de la posibilidad del Cliente de adquirir los Saltos de Skydive Pichilemu por otros medios realizando el pago de una forma distinta a la aqu&iacute; se&ntilde;alada.</span></li>
                <li ><span >El horario de Reserva Salto T&aacute;ndem estar&aacute; sujeto seg&uacute;n disponibilidad y previa confirmaci&oacute;n del pago total del servicio.</span></li>
                <li ><span >La duraci&oacute;n de la actividad aproximada es de medio d&iacute;a, salvo casos fortuitos o de fuerza mayor. Lo anterior debido a la naturaleza de la actividad y las extremas medidas de seguridad que Skydive Pichilemu ha impuesto. Por lo anterior, podr&aacute;n existir factores clim&aacute;ticos, aeron&aacute;uticos u operativos que puedan hacer variar la hora de la reserva, pudiendo implicar inclusive una cancelaci&oacute;n del salto. Ante esta circunstancia existe la alternativa de reprogramar fecha de salto.</span></li>
              </ul>
              <ul>
                <li ><span >El presente acuerdo no contempla ni constituye seguro m&eacute;dico seguro de responsabilidad civil o cualquier otro seguro de tipo personal u otra cobertura.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>S&Eacute;PTIMO: VALORES Y TARIFAS.</strong></p>
              <p><strong>Valores, Tarifas, Reservas y Promociones:</strong></p>
              <ul>
                <li ><span >El valor o tarifa del salto T&aacute;ndem, como del servicio de fotos y/o videos, puede variar en relaci&oacute;n a las promociones que Skydive Pichilemu haga extensivas al p&uacute;blico y publique por distintos medios. Los precios ser&aacute;n los vigentes al momento de realizar el salto y no existir&aacute; compensaci&oacute;n por modificaciones futuras que este pueda sufrir.</span></li>
                <li ><span >Los precios promocionales son v&aacute;lidos para saltos T&aacute;ndem efectuados durante el periodo de promoci&oacute;n, sin perjuicio de la fecha en que se haya realizado la reserva del mismo.</span></li>
                <li ><span >Si la reserva se mueve a una fecha fuera del periodo de promoci&oacute;n no se aplicar&aacute; el precio promocional, debiendo abonarse la totalidad del valor del salto T&aacute;ndem.</span></li>
                <li ><span >Si la persona ha decidido desistir de realizar el salto t&aacute;ndem, la reserva o el pago hecho para este efecto no ser&aacute;n devueltos. Tampoco por mal clima, en ese caso el salto se re-agenda. Tampoco se realizar&aacute;n devoluciones por cambio de fases debido a la Pandemia.</span></li>
                <li ><span >Las promociones no son acumulables en ning&uacute;n caso con otros descuentos.</span></li>
                <li ><span >La compra o reserva del salto T&aacute;ndem tiene un plazo m&aacute;ximo de 30 d&iacute;as para ser efectuado contado desde la fecha de pago o abono. Vencido este plazo no procede devoluci&oacute;n o restituci&oacute;n de lo dado o pagado.</span></li>
                <li ><span >Si el cliente se desiste de realizar el salto T&aacute;ndem, la reserva hecha para este efecto no ser&aacute; devuelta, sin perjuicio de lo se&ntilde;alado en el art&iacute;culo 3 bis de la Ley 19.496.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>OCTAVO: HORARIOS</strong><span >:</span></p>
              <ul>
                <li ><span >Para la realizaci&oacute;n del Salto contratado, se debe llegar al drop zone con 2 horas de anticipaci&oacute;n a su realizaci&oacute;n.</span></li>
                <li ><span >La hora y duraci&oacute;n de la actividad programada es meramente Tentativa. No es posible garantizarla. Sin embargo, a la hora que se cita es la hora que se espera tu llegada. El atraso en la hora de presentaci&oacute;n del salto agendado, puede implicar la posibilidad que este pase al final de la lista de saltos programados para ese d&iacute;a.</span></li>
                <li ><span >El paracaidismo es una actividad que se lleva a cabo al aire libre y por tanto est&aacute; sujeto a las condiciones meteorol&oacute;gicas. La seguridad en el paracaidismo es absolutamente prioritaria, y por este motivo, as&iacute; como por causas organizativas de la propia zona de saltos, el horario podr&iacute;a sufrir variaciones, implicando incluso una cancelaci&oacute;n.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>NOVENO: SUGERENCIAS PARA EL D&Iacute;A DEL SALTO.</strong></p>
              <ul>
                <li ><span >Usar ropa abrigada y zapatos c&oacute;modos. Sin joyas.&nbsp; Para aquellas personas que ocupen lentes &oacute;pticos se cuenta con equipos apropiados para su uso sin peligro.</span></li>
                <li ><span >Llegar con 2 hora de anticipaci&oacute;n a la hora programada para saltar</span></li>
                <li ><span >La no presentaci&oacute;n a la hora se&ntilde;alada, no garantiza el vuelo agendado.</span></li>
                <li ><span >Si se presenta bajo la influencia del alcohol o cualquier sustancia sicotr&oacute;pica se anula autom&aacute;ticamente la reserva y el abono no ser&aacute; reembolsado.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>Servicio y Entrega de Videos y Fotograf&iacute;as</strong></p>
              <ul>
                <li ><span >Skydive Pichilemu entrega servicios video para Saltos T&aacute;ndem. Dichos Servicios son realizados exclusivamente por el Staff del Club. Est&aacute; prohibido el uso de c&aacute;maras de cualquier tipo por parte del pasajero durante el salto.</span></li>
                <li ><span >Si bien la grabaci&oacute;n de video en ca&iacute;da libre se realiza bajo est&aacute;ndares profesionales, esto no garantiza que sean sin detalles. Las cambiantes condiciones del tiempo pueden ocasionar diferencias en la calidad de la filmaci&oacute;n.</span></li>
                <li ><span >Si por alg&uacute;n motivo no hay material de video, se reembolsar&aacute; el monto cancelado por este servicio, sin existir otra forma de compensaci&oacute;n.</span></li>
                <li ><span >En los casos de contrataci&oacute;n del servicio de video el plazo m&aacute;ximo de entrega ser&aacute; siete d&iacute;as h&aacute;biles, contado desde el d&iacute;a de la realizaci&oacute;n del salto T&aacute;ndem. A quienes hayan contratado este servicio se les notificar&aacute; v&iacute;a correo electr&oacute;nico el momento en que est&eacute; disponible este servicio y la forma de acceder a ellos.</span></li>
                <li ><span >El material se env&iacute;a por Wetransfer, y se mantendr&aacute; a su disposici&oacute;n en l&iacute;nea durante el plazo m&aacute;ximo de 7 d&iacute;as. Transcurrido dicho plazo, los archivos se eliminan autom&aacute;ticamente.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>Cesi&oacute;n de Derechos:</strong></p>
              <ul>
                <li ><span >Se concede a Skydive Pichilemu, la propiedad sobre material fotogr&aacute;fico y de video. Autoriz&aacute;ndose el irrevocable e irrestricto derecho a utilizar y publicar fotograf&iacute;as o im&aacute;genes de video en que se incluya al cliente, para utilizarse en edici&oacute;n, comercio, publicidad y cualquier otro fin, en Chile o en el extranjero y en cualquier formato o medio, pudiendo alterar el mismo sin restricci&oacute;n alguna.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>DECIMO: SUSPENSI&Oacute;N DE ACTIVIDADES:</strong></p>
              <ul>
                <li ><span >La actividad de paracaidismo se encuentra regida por normas y reglamentos propios de toda actividad aeron&aacute;utica. Lo que implica que se trabaja bajo altos est&aacute;ndares de seguridad. Por ser una actividad al aire libre requiere de ciertas condiciones meteorol&oacute;gicas que hagan favorable el vuelo y salto. Por ello, queda a criterio de los instructores la suspensi&oacute;n de la actividad por cualquier motivo meteorol&oacute;gico o de cualquier otra &iacute;ndole de riesgo. En este caso las personas que tengan un salto T&aacute;ndem reservado y que sea suspendido conforme al presente apartado, podr&aacute;n optar por&nbsp; elegir una nueva fecha (reagendar en la que haya plazas libres y siempre antes de 2 meses). En el aer&oacute;dromo de Tapihue por Skydive Aloha, o bien solicitar el rembolso del dinero,&nbsp;renunciando a cualquier otro tipo de compensaci&oacute;n por gastos efectuados, por ejemplo, por desplazamiento, alojamiento, etc.</span></li>
                <li ><span >Para Skydive Pichilemu la prioridad es la seguridad de nuestra actividad y de nuestros visitantes.</span></li>
              </ul>
              <p><br /><br /></p>
              <p><strong>D&Eacute;CIMO PRIMERO. DEVOLUCIONES.</strong></p>
              <p><strong>Pol&iacute;ticas de Devoluci&oacute;n</strong></p>
              <p><span >Skydive Pichilemu podr&aacute; aceptar y realizar la devoluci&oacute;n o reembolso de alg&uacute;n servicio siempre y cuando se cumpla con todas y cada una de las condiciones o circunstancias que se establecen a continuaci&oacute;n:</span></p>
              <p>&nbsp;</p>
              <p><strong>Procede Devoluci&oacute;n</strong></p>
              <ul>
                <li ><span >Problemas con Operaciones: Si existen problemas de car&aacute;cter operacional en la zona de salto se autoriza reagendar para nueva fecha o la devoluci&oacute;n o reembolso.</span></li>
                <li ><span >Problemas M&eacute;dicos: Si el pasajero T&aacute;ndem tiene alg&uacute;n problema m&eacute;dico el cual le impide saltar, se autoriza la devoluci&oacute;n siempre y cuando se acredite con certificado m&eacute;dico el impedimento de salud que impide su participaci&oacute;n en la fecha pactada.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>No Procede Devoluci&oacute;n:</strong></p>
              <ul>
                <li ><span >Problemas de Clima: Por ser una actividad al aire libre requiere de ciertas condiciones meteorol&oacute;gicas que hagan favorable el vuelo y salto. Si por cualquier motivo meteorol&oacute;gico por ejemplo lluvia, que a criterio de los instructores se considera aconsejable por una cuesti&oacute;n de seguridad suspender la actividad. Podr&aacute;n optar por elegir una nueva fecha (la cual se coordinar&aacute; con el encargado de ventas).</span></li>
                <li ><span >Si se presenta bajo la influencia del alcohol o cualquier sustancia sicotr&oacute;pica se anula autom&aacute;ticamente la reserva y el abono no ser&aacute; reembolsado, lo anterior, por ser responsabilidad del Cliente el presentarse en condiciones f&iacute;sicas y sicol&oacute;gicas &oacute;ptimas para realizar el Salto, pudiendo poner en riesgo su integridad f&iacute;sica y la del instructor con estas actitudes, generando adem&aacute;s la puesta en acci&oacute;n de toda la log&iacute;stica de Skydive Pichilemu.</span></li>
                <li ><span >Si se desiste de realizar el salto T&aacute;ndem, la reserva hecha para este efecto no ser&aacute; devuelta, sin perjuicio de lo se&ntilde;alado en el art&iacute;culo 3 bis de la Ley 19.496.</span></li>
                <li ><span >Si la informaci&oacute;n proporcionada como peso, edad o m&eacute;dica no resultase cierta (falsa), se suspender&aacute; el salto y no se har&aacute; devoluci&oacute;n de la reserva, lo anterior, por ser responsabilidad del Cliente la entrega de esta informaci&oacute;n y que genera la puesta en acci&oacute;n de toda la log&iacute;stica de Skydive Pichilemu, quien no tiene responsabilidad alguna en la informaci&oacute;n falsa.</span></li>
                <li ><span >Los precios promocionales son v&aacute;lidos para saltos T&aacute;ndem efectuados durante el periodo de promoci&oacute;n, sin perjuicio de la fecha en que se haya realizado la reserva del mismo. De no concretarse el salto durante este plazo no proceder&aacute; devoluci&oacute;n de lo pagado por este concepto y en el caso que el cliente desee realizar el salto fuera del per&iacute;odo de promoci&oacute;n lo podr&aacute; hacer previo pago de la diferencia de precio que exista entre lo pagado promocionalmente y aquel efectivo de la fecha a realizar el Salto.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>WAIVER:</strong></p>
              <p><span >Al completar el Waiver presencialmente se dar&aacute; entendido que usted ha firmado el formulario y aceptado nuestras pol&iacute;ticas.</span></p>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO SEGUNDO: LIBERACI&Oacute;N DE RESPONSABILIDAD.</strong></p>
              <ul>
                <li ><span >Skydive Pichilemu asume el desarrollo y ejecuci&oacute;n de la actividad de paracaidismo. Por tanto, ser&aacute; responsabilidad de Skydive Pichilemu designar a las personas id&oacute;neas para su correcta ejecuci&oacute;n y que cubrir&aacute;n cada una de las &aacute;reas necesarias. (Instructores, pilotos, empacadores, camar&oacute;grafos, etc.).</span></li>
                <li ><span >En atenci&oacute;n a conocer y aceptar los riesgos involucrados, el pasajero T&aacute;ndem firmar&aacute; una declaraci&oacute;n voluntaria de aceptaci&oacute;n de riesgos y renuncia de acciones civiles y/o penales, liberando de toda responsabilidad, cualquiera sea su naturaleza a empleados, miembros y en general a todas las personas naturales o jur&iacute;dicas de Skydive Pichilemu y Skydive Aloha. As&iacute; mismo cualquier otra acci&oacute;n que tenga como objeto indemnizar perjuicios como lucro cesante, da&ntilde;o emergente o da&ntilde;o moral, ejercidas a t&iacute;tulo personal&nbsp; o por terceros actuando en nombre o en representaci&oacute;n.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO TERCERO: FORMACI&Oacute;N DEL CONSENTIMIENTO Y MODIFICACIONES.</strong></p>
              <p><span >Trat&aacute;ndose de las compras realizadas a trav&eacute;s del Sitio y del presente Acuerdo, el consentimiento se formar&aacute; al momento de que el Usuario acepte inequ&iacute;vocamente la compra.</span></p>
              <p><span >En caso de que el precio de un producto, por un error involuntario de Skydive Pichilemu, sea publicado a un precio inferior o superior al real valor de este, la empresa se pondr&aacute; en contacto con los usuarios para resolver la situaci&oacute;n de la manera m&aacute;s apropiada y a la brevedad.</span></p>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO CUARTO: OBLIGACIONES</strong><span >.</span></p>
              <p><span >El Usuario se obliga a:</span></p>
              <ol>
                <li ><span >A que toda la informaci&oacute;n que proporcione a Skydive Pichilemu, personal, o de cualquier naturaleza, por cualquier medio, sea veraz, fidedigna y comprobable.</span></li>
                <li ><span >A conocer y aceptar las Pol&iacute;ticas de Privacidad.</span></li>
                <li ><span >A usar el Sitio, as&iacute; como su contenido, s&oacute;lo con prop&oacute;sitos que caen dentro del marco legal y regulatorio.</span></li>
                <li ><span >A no distribuir desde el Sitio informaci&oacute;n, mensajes, posts o contenidos que pudieren ser de car&aacute;cter fraudulento, enga&ntilde;oso (incluyendo publicidad enga&ntilde;osa de otros productos o servicios ajenos a Skydive Pichilemu), discriminatorio, violento, amenazante, de acoso, difamatorio, denigratorio, y en general, que pudiere da&ntilde;ar a terceros y/o a Skydive Pichilemu. En caso de incumplimiento de esta obligaci&oacute;n, Skydive Pichilemu quedar&aacute; facultado para eliminar del Sitio dicha informaci&oacute;n, mensajes, posts o contenidos.</span></li>
                <li ><span >A no distribuir desde el Sitio sin la previa autorizaci&oacute;n del titular, informaci&oacute;n o contenidos que se encuentren protegidos por las leyes de propiedad intelectual o derecho de autor.</span></li>
              </ol>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO QUINTO: RESPONSABILIDAD</strong></p>
              <p><span >En ning&uacute;n caso Skydive Pichilemu responder&aacute; por:</span></p>
              <ol>
                <li ><span >La utilizaci&oacute;n indebida que Usuarios o visitantes de El Sitio puedan hacer de los materiales exhibidos, de los derechos de propiedad industrial y de los derechos de propiedad intelectual.</span></li>
                <li ><span >Da&ntilde;os o eventuales da&ntilde;os y perjuicios que se le puedan causar a los Compradores y/o Usuarios por el funcionamiento de las herramientas de b&uacute;squeda y de los errores que se generen por los elementos t&eacute;cnicos de El Sitio o motor de b&uacute;squeda.</span></li>
                <li ><span >Contenidos de las p&aacute;ginas a las que los Compradores o Usuarios puedan acceder con o sin autorizaci&oacute;n de Skydive Pichilemu.</span></li>
                <li ><span >El acceso de menores de edad o personas sin capacidad, bajo los t&eacute;rminos de la legislaci&oacute;n correspondiente, a los contenidos adherentes a la relaci&oacute;n contractual que surja de El Sitio.</span></li>
                <li ><span >P&eacute;rdida, mal uso o uso no autorizado de su c&oacute;digo de validaci&oacute;n, ya sea por parte del Usuario y/ o comprador Compradores, o de terceros, luego de realizada la compra en la forma expresada en los T&eacute;rminos y Condiciones. Asimismo, las partes reconocen y dejan constancia que la plataforma computacional proporcionada por Skydive Pichilemu no es infalible, y por tanto, durante la vigencia del presente Contrato pueden verificarse circunstancias ajenas a la voluntad de Skydive Pichilemu, que impliquen que El Sitio o la plataforma computacional no se encuentren operativos durante un determinado periodo de tiempo.</span></li>
                <li ><span >Informaci&oacute;n de Skydive Pichilemu o sus servicios que se encuentre en sitios distintos a&nbsp;</span><span >skydivepichilemu.cl</span></li>
              </ol>
              <p><span >Skydive Pichilemu no se hace responsable por los virus ni otros elementos en los documentos electr&oacute;nicos almacenados en los sistemas inform&aacute;ticos de los Usuarios. Skydive Pichilemu no responder&aacute; de los perjuicios ocasionados al Cliente, provenientes del uso inadecuado de las tecnolog&iacute;as puestas a disposici&oacute;n de &eacute;ste, cualquiera sea la forma en la cual se utilicen inadecuadamente estas tecnolog&iacute;as. Skydive Pichilemu no responder&aacute; de los da&ntilde;os producidos a El Sitio por el uso indebido y de mala fe de los Usuarios y/o Compradores. No obstante, en el evento de realizarse un doble pago por un Usuario o Comprador en El Sitio, Skydive Pichilemu devolver&aacute; la suma del sobrepago, dentro de los 3 d&iacute;as siguientes a la recepci&oacute;n del respectivo reclamo escrito del Usuario o Comprador, en el que se anexen los originales de los comprobantes del pago adicional a lo adquirido.</span></p>
              <p><strong>Datos personales</strong><span>:</span></p>
              <p><span>Los Usuarios y/o Compradores garantizan que la informaci&oacute;n que suministran para la celebraci&oacute;n del contrato es veraz, completa, exacta y actualizada.</span></p>
              <p><span>De conformidad con la Ley 19.628 los datos personales que suministren en el Sitio Web pasar&aacute;n a formar parte de una base de datos de Skydive Pichilemu y ser&aacute;n destinados &uacute;nica y exclusivamente para ser utilizados en los fines que motivaron su entrega y especialmente para la comunicaci&oacute;n en general entre la empresa y sus clientes y validar los datos de la compra. Los datos no ser&aacute;n comunicados a otras empresas sin la expresa autorizaci&oacute;n de su titular ni ser&aacute;n transferidos internacionalmente.</span></p>
              <p><span>Skydive Pichilemu jam&aacute;s solicita datos personales o financieros a trav&eacute;s de correo electr&oacute;nico</span></p>
              <p><span>Skydive Pichilemu presume que los datos han sido incorporados por su titular o por persona autorizada por &eacute;ste, as&iacute; como que son correctos y exactos. Los Usuarios y/o Compradores con la aceptaci&oacute;n de los presentes T&eacute;rminos y Condiciones manifiestan que los datos de car&aacute;cter personal que aporte a trav&eacute;s de los formularios online en la p&aacute;gina web de Skydive Pichilemu pueden ser utilizados para Ofertas posteriores y distintas a las ofrecidas en El Sitio.</span></p>
              <p><span>Sin perjuicio de lo anterior, Skydive Pichilemu garantiza a los usuarios el libre ejercicio de sus derechos de informaci&oacute;n, modificaci&oacute;n, cancelaci&oacute;n y bloqueo de sus datos personales establecidos en la Ley 19.628. Por consiguiente, los compradores podr&aacute;n realizar requerimientos que digan relaci&oacute;n con dichos derechos, y en un plazo m&aacute;ximo de dos d&iacute;as corridos, Skydive Pichilemu deber&aacute; dar respuesta e implementar efectivamente esta solicitud.</span></p>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO SEXTO: ALCANCE DE LAS CONDICIONES INFORMADAS EN EL SITIO</strong></p>
              <p><span>Skydive Pichilemu no modificar&aacute; las condiciones bajo las cuales haya contratado con los consumidores en este sitio. Mientras aparezcan en este sitio, los precios informados estar&aacute;n a disposici&oacute;n del usuario, aunque no sean los mismos que se ofrezcan en otros canales de venta de Skydive Pichilemu, como tiendas f&iacute;sicas, cat&aacute;logos, televisi&oacute;n, radio, u otros.</span></p>
              <p><span>Las promociones que ofrecidas en el sitio no necesariamente ser&aacute;n las mismas que Skydive Pichilemu ofrezca por otros canales de venta.</span></p>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO S&Eacute;PTIMO: PROPIEDAD INTELECTUAL</strong></p>
              <p>&nbsp;</p>
              <p><span>Todos los contenidos incluidos en este sitio, como textos, material gr&aacute;fico, logotipos, &iacute;conos de botones, c&oacute;digos fuente, im&aacute;genes, audio clips, descargas digitales y compilaciones de datos, son propiedad de Skydive Pichilemu o de sus proveedores de contenidos, y est&aacute;n protegidos por las leyes chilenas e internacionales sobre propiedad intelectual. Los materiales gr&aacute;ficos, logotipos, encabezados de p&aacute;ginas, frases publicitarias, iconos de botones, textos escritos y nombres de servicios incluidos en este sitio son marcas comerciales, creaciones o im&aacute;genes comerciales de propiedad de Skydive Pichilemu en Chile y en otros pa&iacute;ses. Dichas marcas, creaciones e im&aacute;genes comerciales no se pueden usar en relaci&oacute;n a ning&uacute;n producto o servicio que pueda causar confusi&oacute;n entre los clientes y en ninguna forma que desprestigie o desacredite a Skydive Pichilemu. Las dem&aacute;s marcas comerciales que no sean de propiedad de Skydive Pichilemu y que aparezcan en este sitio pertenecen a sus respectivos due&ntilde;os.</span></p>
              <p><span>Todos los derechos no expresamente otorgados en estos T&eacute;rminos y Condiciones son reservados por Skydive Pichilemu o sus cesionarios, proveedores, editores, titulares de derechos u otros proveedores de contenidos. Ning&uacute;n producto, imagen o sonido pueden ser reproducidos, duplicados, copiados, vendidos, revendidos, visitados o explotados para ning&uacute;n fin, en todo o en parte, sin el consentimiento escrito previo de Skydive Pichilemu. No se puede enmarcar o utilizar t&eacute;cnicas de enmarcaci&oacute;n para encerrar alguna marca comercial, logotipo u otra informaci&oacute;n registrada o patentada (incluyendo im&aacute;genes, texto, disposici&oacute;n de p&aacute;ginas, o formulario) de Skydive Pichilemu, sin nuestro consentimiento escrito previo. Tampoco se puede usar meta etiquetas ni ning&uacute;n otro &ldquo;texto oculto&rdquo; que use el nombre o marcas comerciales de Skydive Pichilemu, sin autorizaci&oacute;n escrita previa de esta empresa. Se proh&iacute;be hacer un uso indebido de este sitio o de estas marcas, licencias o patentes. Lo anterior, sin perjuicio de las excepciones expresamente se&ntilde;aladas en la ley.</span></p>
              <p>&nbsp;</p>
              <p><strong>D&Eacute;CIMO OCTAVO: LEGISLACI&Oacute;N APLICABLE y COMPETENCIA</strong></p>
              <p><span>Los presentes t&eacute;rminos y condiciones se rigen por las leyes de la Rep&uacute;blica de Chile. Cualquier controversia o conflicto derivado de la utilizaci&oacute;n del sitio web de Skydive Pichilemu, sus T&eacute;rminos y Condiciones y las Pol&iacute;ticas de Privacidad, su validez, interpretaci&oacute;n, alcance o cumplimiento, ser&aacute; sometida a las leyes aplicables de la Rep&uacute;blica de Chile.</span></p>
              <p><br /><br /></p>
              <p><strong>D&Eacute;CIMO NOVENO: VALIDEZ Y VIGENCIA DE LOS T&Eacute;RMINOS Y CONDICIONES</strong></p>
              <p><span>El presente acuerdo, mientras el Sitio se encuentre disponible, tendr&aacute; vigencia indefinida, salvo que Skydive Pichilemu ejerza su facultad discrecional de ponerle t&eacute;rmino, en cuyo caso los Usuarios ser&aacute;n debidamente notificados por cualquier medio fehaciente.</span></p>
              <p>&nbsp;</p>
              <p><strong>Documentos Anexos:</strong></p>
              <p><span>Para efectos de reserva y realizaci&oacute;n del Salto T&aacute;ndem, este documento forma parte integral de la informaci&oacute;n proporcionada por Skydive Pichilemu, en mi proceso de instrucci&oacute;n previa.</span></p>
              <p>&nbsp;</p>
              <p><strong>Retracto</strong></p>
              <ul>
                <li><span>&ldquo;Se excluye de todos los contratos celebrados a trav&eacute;s del Sitio el ejercicio del derecho de retracto contemplado en el art&iacute;culo 3&ordm; bis de la Ley N&deg;19.496, que establece normas sobre Protecci&oacute;n de los Derechos de los Consumidores.&rdquo;</span></li>
              </ul>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <ul>
                <li><strong>DECLARACI&Oacute;N DE CONDIC&Iacute;&Oacute;N F&Iacute;SICA:<span> Declaro encontrarme en buen estado f&iacute;sico y mental, y que en la actualidad no sufro de enfermedades o circunstancias relacionadas que impidan o condicionen mi participaci&oacute;n en este deporte, tales como, embarazo, epilepsia, convulsiones, problemas cardiacos, enfermedades mentales severas, presi&oacute;n arterial elevada, ni ser adicto a psicotr&oacute;picos, estupefacientes, alcohol o estar bajo la influencia de dichas sustancias. En el supuesto de padecer alguno o varios de los siguientes s&iacute;ntomas o enfermedades, deber&aacute; necesariamente declararlo ante su Instructor, antes de realizar la actividad de paracaidismo, declaro contar con una capacidad f&iacute;sica acorde a la actividad, y No padecer desmayos frecuentes, mareos, amnesia, presi&oacute;n sangu&iacute;nea alta, fractura de tobillos, enfermedades cr&oacute;nicas de los o&iacute;dos o sinusitis, o haber sufrido una herida importante en la cabeza o cualquier situaci&oacute;n que requiera el uso regular de medicamentos que pueda mermar la capacidad psicomotriz. Declaro no pesar m&aacute;s de 100 Kg o no tener un sobrepeso tal que me impida o dificulte realizar las actividades requeridas. De igual manera DECLARO no haberme dislocado un hombro con anterioridad, no estar con esguince de tobillo, as&iacute; como entender y comprometerme a alzar mis piernas en el aterrizaje del salto en paraca&iacute;das, modalidad T&aacute;ndem, con el objeto de permitir al instructor efectuar libremente las maniobras de aterrizaje.</span></strong></li>
              </ul>
              <p>&nbsp;</p>
              <ul>
                <li><span> Declaro, comprendo y acepto los t&eacute;rminos descritos.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>EJERCICIO DE ACCIONES LEGALES</strong></p>
              <p><span>Asumo plenamente y comprendo el riesgo que conlleva la pr&aacute;ctica deportiva de esta actividad de paracaidismo en la cual participar&eacute;, y aun reconociendo las medidas de seguridad tomadas por los Instructores de Skydive Pichilemu y Skydive Aloha, haber recibido toda la instrucci&oacute;n correspondiente a la actividad deportiva en la cual participar&eacute;, asumo voluntariamente todos y cada uno de los riesgos asociados que conlleva la pr&aacute;ctica del deporte de paracaidismo. De tal modo que, aun conociendo la diligencia y buenas pr&aacute;cticas del club, su experiencia, sus certificaciones y las medidas tomadas a disposici&oacute;n de todos los medios a su alcance en aras a evitar o minimizar los riesgos, admito, reconozco y asumo la existencia de riesgos inevitables que necesariamente conlleva la actividad. Reconozco y asumo el alcance, naturaleza y posibilidad de los riesgos asociados a los vuelos en la aeronave Cessna 182, matr&iacute;cula CC-LFJ. Adem&aacute;s, puedo observar que tanto el piloto y la aeronave se encuentran debidamente certificados por la Direcci&oacute;n General de Aeron&aacute;utica Civil de Chile (DGAC) y conforme a la normativa nacional vigente cumplen los requisitos para realizar esta actividad en las instalaciones del aer&oacute;dromo de Pichilemu y/o en el borde costero de la ciudad de Pichilemu</span></p>
              <ul>
                <li><span> Declaro, comprendo y acepto los t&eacute;rminos descritos.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>ASPECTOS ADMINISTRATIVOS</strong></p>
              <p><span>Por favor aseg&uacute;rate de contar con el tiempo suficiente para poder disfrutar de tu experiencia. Se te informar&aacute; la hora de tu salto y deber&aacute;s llegar 1 hora antes de la hora asignada. Debes ser puntual y respetar la hora de reserva, de lo contrario corres el riesgo de saltar al final del d&iacute;a (en caso de que exista disponibilidad), sin derecho a solicitar reembolso o reagendar tu salto. Para solicitar y asegurar tu salto t&aacute;ndem, puedes pagar el monto total o la reserva (la reserva se realiza con un abono de $100.000 CLP). En el caso que solamente abones el valor de la reserva, deber&aacute;s cancelar la diferencia restante antes de empezar el vuelo. Otorgo a Skydive Pichilemu la autorizaci&oacute;n sin l&iacute;mite de uso, para difundir y publicar, en cualquier tipo de medio o redes sociales, mis im&aacute;genes o videos del salto, ahora o en el futuro.</span></p>
              <ul>
                <li><span> Declaro, comprendo y acepto los t&eacute;rminos descritos.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><strong>REEMBOLSOS</strong></p>
              <p><span>Si por los motivos que fuesen, no puedes o no quieres realizar el salto y avisas con un m&iacute;nimo de 72 horas antes de la fecha de la reserva, Skydive Pichilemu te reembolsar&aacute; el monto total pagado. Si por los motivos que fuesen, no puedes o no quieres realizar el salto y avisas pasadas las 48 horas y menos a las 72 horas, perder&aacute;s el equivalente al 50% del valor pagado. Si&nbsp; avisas menos a 48 horas del salto no se reembolsar&aacute; dinero. Si por los motivos que fuesen, no puedes o no quieres realizar el salto, pero ya has recibido la instrucci&oacute;n en el centro de paracaidismo, no existir&aacute; reembolso alguno, perdiendo el 100% de los servicios contratados. Si te arrepientes de realizar el salto t&aacute;ndem, estando a bordo de la aeronave, no est&aacute;s obligado a realizar el salto, debiendo aterrizar junto a tu instructor en la aeronave. No existir&aacute; reembolso alguno, perdiendo el 100% de los servicios contratados.</span></p>
              <p>&nbsp;</p>
              <p><strong>RESUMEN</strong></p>
              <ul>
                <li ><span>Cliente da aviso igual o m&aacute;s a 72 horas de anticipaci&oacute;n, se devuelve el 100% del dinero.</span></li>
                <li><span>Cliente da aviso con menos a 72 horas, igual o m&aacute;s de 48 horas de anticipaci&oacute;n, se&nbsp;devuelve el 50% del dinero.</span></li>
                <li><span>Cliente da aviso menos de 48 horas de anticipaci&oacute;n, no se devuelve el dinero.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><span>Si producto de la meteorolog&iacute;a (viento o nubosidad) o alguna discrepancia que presente la aeronave y que afecte la seguridad, no se puede realizar el salto t&aacute;ndem, buscaremos una fecha dentro del mismo mes o el siguiente, para re-agendar la actividad en el Aer&oacute;dromo de Tapihue, Casablanca bajo Skydive Aloha. De no ser posible, se reintegrar&aacute;n los servicios cancelados menos el 10%.</span></p>
              <ul>
                <li><span>Declaro, comprendo y acepto los t&eacute;rminos descritos.</span></li>
              </ul>
              <p>&nbsp;</p>
              <p><span>Acepto todas las condiciones previamente descritas.</span></p>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  render_servicesinfo() {
    return (
      <div className="container">
        <div className="saas-section-title title">
          <h2>Tipos de saltos</h2>
          <p className="sub-title">
            Conoce más sobre los tipos de saltos que <b>Skydive Pichilemu </b>
            tiene para ofrecerte
          </p>
          <br />
          <Modals />
        </div>
      </div>
    );
  }

  render_form() {
    return (
      <form id="servicesform" className="services-form" onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-md-12 input-services">
            <div className="md-form mb-0">
              <input
                id="name"
                placeholder="Nombre *"
                value={this.state.name}
                type="text"
                className={
                  "form-control text" +
                  (this.state.isErrorName ? " is-invalid" : "")
                }
                required={true}
                disabled={this.state.isLoading}
                data-error="Ingresa tu nombre"
                onChange={this.handleChange}
              />
              {this.state.isErrorName && (
                <div className="invalid-feedback">
                  Debes ingresar tu nombre
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 input-services">
            <div className="md-form mb-0">
              <input
                id="last_name"
                placeholder="Apellido *"
                value={this.state.last_name}
                type="text"
                className={
                  "form-control text" +
                  (this.state.isErrorLastname ? " is-invalid" : "")
                }
                required={true}
                disabled={this.state.isLoading}
                data-error="Ingresa tu apellido"
                onChange={this.handleChange}
              />
              {this.state.isErrorLastname && (
                <div className="invalid-feedback">
                  Debes ingresar tu apellido
                </div>
              )}
            </div>
          </div>

          <div className="col-md-12 input-services">
            <div className="md-form mb-0">
              <input
                id="phone"
                placeholder="Teléfono (987654321)*"
                value={this.state.phone}
                type="text"
                className={
                  "form-control text" +
                  (this.state.isErrorPhone ? " is-invalid" : "")
                }
                required={true}
                disabled={this.state.isLoading}
                data-error="Ingresa tu teléfono"
                onChange={this.handleChange}
              />
              {this.state.isErrorPhone && (
                <div className="invalid-feedback">
                  Debes ingresar tu teléfono
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 input-services">
            <div className="md-form mb-0">
              <input
                id="email"
                placeholder="Email *"
                value={this.state.email}
                type="text"
                className={
                  "form-control text" +
                  (this.state.isErrorEmail ? " is-invalid" : "")
                }
                required={true}
                disabled={this.state.isLoading}
                data-error="Ingresa tu correo"
                onChange={this.handleChange}
              />
              {this.state.isErrorEmail && (
                <div className="invalid-feedback">
                  Debes ingresar tu email
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 input-services">
            <div className="md-form mb-0 event-form">
              <h4>Seleccione el tipo de salto *</h4>
            </div>
          </div>
          <div className="col-md-12">

            <div className="md-form mb-0">
              <div className="form-check form-check-inline-block">
                <input
                  className={"form-check-input" + (this.state.isErrorEventType ? " is-invalid" : "")}
                  type="radio"
                  value="Tandem"
                  checked={this.state.eventType === "Tandem"}
                  onChange={this.handleChangeEventType}
                  disabled={this.state.isLoading || this.state.isLoadingEvent}
                  required
                />
                <label className="form-check-label" name="saltoTandem">
                  Salto Tándem
                </label>
              </div>
              <div className="form-check form-check-inline-block">
                <input
                  className={"form-check-input" + (this.state.isErrorEventType ? " is-invalid" : "")}
                  data-error="Debe seleccionar un tipo de salto"
                  type="radio"
                  value="Deportivo"
                  checked={this.state.eventType === "Deportivo"}
                  disabled={this.state.isLoading || this.state.isLoadingEvent}
                  onChange={this.handleChangeEventType}
                />
                <label className="form-check-label" name="saltoDeportivo">
                  Salto Deportivo
                </label>
                {this.state.isErrorEventType && (<span className="invalid-feedback"><strong>Debe seleccionar el tipo de salto</strong></span>)}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="md-form mb-0 event-form">
              <h4>Seleccione el evento *</h4>
            </div>
          </div>
          <div className="col-md-12">
            {
              this.state.isLoadingEvent ?
                <span>Cargando eventos...</span>
                :
                <select id="event" className="form-control" value={this.state.event} onChange={this.handleChange} disabled={this.state.isLoading} >
                  <option>Seleccione evento...</option>
                  {
                    this.state.events.map((event, index) => {
                      return event.quote == 0 ? <option value={event.event} disabled={true}>{"[" + event.date + "] $" + event.price + " (" + event.quote + " sin cupos disponibles)"}</option> : <option value={event.event} selected={true} disabled={false}>{"[" + event.date + "] $" + event.price + " (" + event.quote + " cupos disponibles)"}</option>
                    })
                  }
                </select>
            }
          </div>
        </div>
        {this.render_Terms()}
        <div className="row">
          <div className="col-md-12">
            <ReCAPTCHA
              sitekey={config.ReCAPTCHA2}
              onChange={this.handleChangeRC}
              className="recaptcha"
            />
            <div className="help-block with-errors">
              {this.state.isErrorCaptcha && (
                <div className="invalid-feedback">
                  Debes completar captcha
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-center">
          {(this.state.isError || this.state.isSuccess) && (
            <div
              className={
                "alert alert-" +
                (this.state.isError ? "danger" : "success") +
                " col-lg-12 col-md-12"
              }
              role="alert"
            >
              {this.state.isError
                ? (this.state.message ? this.state.message : "Error al realizar reserva, por favor intenta nuevamente.")
                : "Reserva realizada exitosamente."}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => this.handleClose()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <button
            type="submit"
            className="btn btn-primary enviar"
            disabled={!this.state.isChecked || this.state.isLoading}
          >
            {this.state.isLoading ? "Reservando..." : "Reservar Salto"}
          </button>
          <div id="msgSubmit" className="h3 text-center hidden"></div>
          <div className="clearfix"></div>
        </div>
      </form>
    );
  }

  render_voucher() {
    return (
      <div className="voucher">
        <img src={require("../../images/logo.PNG")} />
        <h1 className=".about-area.ptb-100 .container voucher-title">Voucher de reserva</h1>
        <h2 id="cont">Se realizó tu reserva de manera exitosa, para terminar el proceso de pago debes acceder al siguiente <a href="https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=105406986" target="blank">link de pago</a></h2>
        <h2 id="cont">Para más información puedes comunicarte al <a target="blank"
          href="https://api.whatsapp.com/send/?phone=%2B56975196533&text=¡Hola!+necesito+más+información.&app_absent=0">WhatsApp</a> y nuestro equipo estará encantado de ayudarte</h2>
        <h2 id="cont">Gracias por preferir a Skydive Pichilemu</h2>
        <button type="button" className="btn btn-primary voucher-btn" onClick={() => this.handleClose()}>
          Volver a reservar un salto
        </button>
      </div>
    )
  }

  render_event() {
    return (
      <div id="reserva" className="container">
        <div className="saas-section-title title">
          <p className="sub-title">
            <b>Skydive Pichilemu</b> te ofrece la mejor experiencia en saltos
            tándem y deportivos. <br />{" "}
          </p>
          <br />
          <div className="row align-items-center reserva">
            <div className="col-md-6">
              <h2>Realiza tu Reserva</h2>
              <img src={require("../../images/catalog.jpg")} />
            </div>
            <div className="col-md-6">
              <div className="col-md-9 mb-md-0 mb-5">
                {
                  this.state.isSuccess ? this.render_voucher() : this.render_form()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section id="services" className="about-area ptb-100 con">
        <div className="bar"></div>
        {this.render_servicesinfo()}
        {this.render_event()}
      </section>
    );
  }
}

export default Services;
