import React, { Component } from "react";
import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      isSuccess: false,
      name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      key: "",
      isErrorName: false,
      isErrorLastname: false,
      isErrorPhone: false,
      isErrorEmail: false,
      isErrorSubject: false,
      isErrorMessage: false,
      isErrorCaptcha: false,
    };
  }
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  validate() {
    var errors = {};
    const textValidate = /^[a-zA-ZÀ-ÿ\s]{3,40}$/ //Solo Letras y espacios, pueden llevar acentos.
    const numberValidate = /^\d{9}$/ // Se valida que solo tenga 8 numeros.
    const emailValidate = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/ // Se valida el correo.

    errors.isErrorName = !textValidate.test(this.state.name)
    errors.isErrorLastname = !textValidate.test(this.state.last_name)
    errors.isErrorPhone = !numberValidate.test(this.state.phone)
    errors.isErrorEmail = !emailValidate.test(this.state.email)
    errors.isErrorSubject = this.state.subject.length < 3;
    errors.isErrorMessage = this.state.message.length < 3;
    errors.isErrorCaptcha = this.state.key.length < 64;
    errors.hasError =
      errors.isErrorName ||
      errors.isErrorEmail ||
      errors.isErrorLastname ||
      errors.isErrorPhone ||
      errors.isErrorSubject ||
      errors.isErrorMessage ||
      errors.isErrorCaptcha;
    return errors;
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const errors = this.validate();
    if (errors.hasError) {
      errors.hasError = undefined;
      this.setState({ ...errors });
      return;
    }
    this.setState({ ...errors, isLoading: true });
    try {
      this.contact({
        name: this.state.name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        message: this.state.message,
        key: this.state.key,
      });
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, isError: true, isSuccess: false });
    }
  };
  contact(data) {
    return fetch(config.url.api + "/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      return res.json();
    }).then((response) => {
      if (response.code === 0) {
        this.setState({
          isLoading: false,
          isError: false,
          isSuccess: true,
          name: "",
          last_name: "",
          email: "",
          phone: "",
          subject: "",
          key: "",
          message: "",
        });
      } else {
        this.setState({ isLoading: false, isError: true, isSuccess: false });
      }
    });
  }
  handleClose = () => {
    this.setState({ isSuccess: false, isError: false });
  };
  handleChangeRC = (value) => {
    this.setState({ key: value, isEmailError: false, isKeyError: false });
  };
  render_info() {
    return (
      <div className="container">
        <div className="saas-section-title title">
          <h2>Información</h2>
        </div>
        <div className="row contact-row">
          <div className="col-md-6">
            <div className="container">
              <div className="saas-section-title contact">
                <h3>Despegamos: Club aéreo de Pichilemu</h3>
              </div>
              <iframe className="iframecontact" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Club%20a%C3%A9reo%20de%20Pichilemu+(Club%20a%C3%A9reo%20de%20Pichilemu)&amp;t=k&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/car-satnav-gps/">GPS devices</a>
              </iframe>
            </div>
          </div>
          <div className="col-md-6">
            <div className="container">
              <div className="saas-section-title contact">
                <h3>Aterrizamos: Playa La Ballena</h3>
              </div>
              <iframe className="iframecontact" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=playa%20la%20ballena%20pichilemu+(Club%20a%C3%A9reo%20de%20Pichilemu)&amp;t=k&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/car-satnav-gps/">Car GPS</a>
              </iframe>
            </div>
          </div>
          <div className="saas-section-title">
            <h4><strong>Contaremos con traslado desde el lugar de aterrizaje hasta el aeródromo de Pichilemu.</strong></h4>
          </div>

        </div>
      </div>
    );
  }
  render_contact() {
    return (
      <div className="container section-padding3">
        <div className="saas-section-title contact">
          <h2 className="h1-responsive font-weight-bold text-center my-4">
            Contáctanos
          </h2>
        </div>
        <div className="row contact-row">
          <div className="col-md-5">
            <img src={require("../../images/tandem.png")} />
          </div>
          <div className="col-lg-9">
            <form id="contactForm" onSubmit={this.handleSubmit}>
              <div className="left">
                <div className="col-md-12 input-contact">
                <div className="md-form mb-0 ">
                  <input
                    id="name"
                    placeholder="Ingrese su nombre *"
                    value={this.state.name}
                    type="text"
                    className={
                      "form-control" +
                      (this.state.isErrorName ? " is-invalid" : "")
                    }
                    required={true}
                    data-error="Ingresa tu nombre"
                    onChange={this.handleChange}
                  />
                  {this.state.isErrorName && (
                    <div className="invalid-feedback">
                      Debes ingresar tu nombre
                    </div>
                  )}
                </div>
                </div>
                <div className="col-md-12 input-contact">
                <div className="md-form mb-0">
                  <input
                    id="last_name"
                    placeholder="Ingrese su apellido *"
                    value={this.state.last_name}
                    type="text"
                    className={
                      "form-control" +
                      (this.state.isErrorLastname ? " is-invalid" : "")
                    }
                    required={true}
                    data-error="Ingresa tu apellido"
                    onChange={this.handleChange}
                  />
                  {this.state.isErrorLastname && (
                    <div className="invalid-feedback">
                      Debes ingresar tu apellido
                    </div>
                  )}
                </div>
                </div>
                <div className="col-md-12 input-contact">
                <div className="md-form mb-0">
                  <input
                    id="email"
                    placeholder="Ingrese su correo *"
                    value={this.state.email}
                    type="email"
                    className={
                      "form-control" +
                      (this.state.isErrorEmail ? " is-invalid" : "")
                    }
                    required={true}
                    data-error="Ingresa tu email"
                    onChange={this.handleChange}
                  />
                  <div className="help-block with-errors">
                    {this.state.isErrorEmail && (
                      <div className="invalid-feedback">
                        Debes ingresar tu email
                      </div>
                    )}
                  </div>
                </div>
                </div>
                <div className="col-md-12 input-contact">
                <div className="md-form mb-0">
                  <input
                    id="phone"
                    placeholder="Ingrese su télefono *"
                    value={this.state.phone}
                    type="text"
                    className={
                      "form-control" +
                      (this.state.isErrorPhone ? " is-invalid" : "")
                    }
                    required={true}
                    data-error="Ingresa el télefono"
                    onChange={this.handleChange}
                  />
                  <div className="help-block with-errors">
                    {this.state.isErrorPhone && (
                      <div className="invalid-feedback">
                        Debes ingresar el télefono
                      </div>
                    )}
                  </div>
                </div>
                </div>
                
              </div>
              <div className="right">
              <div className="col-md-12 input-contact">
              <div className="md-form mb-0">
                  <input
                    id="subject"
                    placeholder="Ingrese el asunto *"
                    value={this.state.subject}
                    type="text"
                    className={
                      "form-control" +
                      (this.state.isErrorSubject ? " is-invalid" : "")
                    }
                    required={true}
                    data-error="Ingresa el asunto"
                    onChange={this.handleChange}
                  />
                  <div className="help-block with-errors">
                    {this.state.isErrorSubject && (
                      <div className="invalid-feedback">
                        Debes ingresar el asunto
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 input-contact">
              <div className="md-form">
                  <textarea
                    id="message"
                    placeholder="Ingrese el mensaje *"
                    value={this.state.message}
                    className={
                      "form-control" +
                      (this.state.isErrorMessage ? " is-invalid" : "")
                    }
                    cols="30"
                    rows="4"
                    required={true}
                    data-error="Escribe el mensaje"
                    onChange={this.handleChange}
                  />
                  <div className="help-block with-errors">
                    {this.state.isErrorMessage && (
                      <div className="invalid-feedback">
                        Debes ingresar el mensaje
                      </div>
                    )}
                  </div>
                </div>
                </div>   
                <div className="row">
                  <div className="col-md-12">
                    <ReCAPTCHA
                      sitekey={config.ReCAPTCHA}
                      onChange={this.handleChangeRC}
                      className="recaptcha"
                    />
                    <div className="help-block with-errors">
                      {this.state.isErrorCaptcha && (
                        <div className="invalid-feedback">
                          Debes completar captcha
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {(this.state.isError || this.state.isSuccess) && (
                    <div
                      className={
                        "alert alert-" +
                        (this.state.isError ? "danger" : "success") +
                        " col-lg-12 col-md-12"
                      }
                      role="alert"
                    >
                      {this.state.isError
                        ? "Error al enviar el mensaje, por favor intenta nuevamente."
                        : "Mensaje enviado exitosamente. Nos comunicaremos contigo prontamente."}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={() => this.handleClose()}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary enviar"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Enviando..." : "Enviar Mensaje"}
                  </button>
                  <div id="msgSubmit" className="h3 text-center hidden"></div>
                  <div className="clearfix"></div>
                </div>
              </div>

            </form>
            <div className="row">
              <div className="col-md-12">
                <p className="w-responsive mx-auto mb-5">
                  También puedes contactarnos y seguirnos a nuestros diferentes medios de comunicación y redes sociales, donde podrás mantenerte al tanto de nuestros eventos y noticias.
                </p>
                <h5>SÍGUENOS EN REDES SOCIALES </h5>
              </div>
            </div>
            <div className="row social-media redes">
              <a
                className="instagram"
                target="blank"
                href="https://www.instagram.com/skydivepichilemu/"
                title="@skydivepichilemu"
              >
                <img src={require("../../images/insta.png")} />
                <br />
                <span>@SkydivePichilemu</span>
              </a>
              <a
                className="instagram"
                target="blank"
                href="https://www.instagram.com/surflyers/"
                title="@surflyers"
              >
                <img src={require("../../images/insta.png")} />
                <br />
                <span>@Surflyers</span>
              </a>

              <a
                className="instagram"
                target="blank"
                href="https://www.instagram.com/skydivealoha/"
                title="@skydivealoha"
              >
                <img src={require("../../images/insta.png")} />
                <br />
                <span>@Skydivealoha</span>
              </a>
            </div>
          </div>

        </div>


      </div>
    );
  }
  render() {
    return (
      <section id="map"className="about-area ptb-100 con">
        <div id="info" className="bar"></div>
        {this.render_info()}
        <div id="contact" className="bar"></div>
        {this.render_contact()}
      </section>
    );
  }
}
export default Contact;
