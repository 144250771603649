import React, { Component } from "react";
import "./Modal.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class Modals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      desc: "",
      show: false
    };
  }
  handleShow = () => {
    this.setState({ show: false });
  }
  tandem() {
    this.setState({
      title: "¿Qué es un Salto Tándem?",
      desc: "El salto tándem es una modalidad del paracaidismo dual, en la cual un aprendiz de paracaidismo o una persona que quiere vivir la experiencia, salta unido a un instructor, mediante un sistema de arnés doble.",
      show: true
    });
  }
  deportivo() {
    this.setState({
      title: "¿Qué es un Salto Deportivo?",
      desc: "Dentro del deporte del paracaidismo podemos encontrar, la modalidad deportiva, que incluye diferentes estilos que se practican actualmente en caída libre, permitiendo volar en grupos, parejas o individualmente.",
      show: true
    });
  }



  render() {
    return (
      <div>
        <div className="row info-saltos">
          <div className="col-md-4 img first">
            <a type="button" onClick={() => this.tandem()}> <img src={require("../../images/salto-desc-1.jpg")} /> </a>
          </div>
          <div className="col-md-4 img second">
            <a type="button" onClick={() => this.deportivo()}> <img src={require("../../images/salto-desc-2.jpg")} /></a>
          </div>
        </div>
        <Modal isOpen={this.state.show} backdrop={false} centered={true} >
          <ModalHeader className="modal-header mod">
            <h3 className="modal-title">{this.state.title}</h3>
          </ModalHeader>
          <ModalBody className="modal-body">
            {this.state.desc}
          </ModalBody>
          <ModalFooter className="modal-footer">
            <button className="btn btn-secondary bn" onClick={() => this.handleShow()}>
              Cerrar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}