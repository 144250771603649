import React, { Component } from "react";
import "./About.css";

class About extends Component {
  render() {
    return (
      <section id="about" className="about-area ptb-100">
        <div className="bar"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="saas-section-title title">
                <h2>Quiénes Somos</h2>
                <p>
                  <b>Skydive Aloha & Surflyers</b> convergen y crean <b>Skydive Pichilemu,</b> una iniciativa única de paracaidismo a efectuar por primera vez en Pichilemu, con el objetivo de impulsar el turismo en la zona y potenciar el club aéreo de la capital del surf, a través de un evento deportivo que se realizará en mayo 2022.
                </p>
                <br />
              </div>
            </div>
            <div className="col-md-6 img">
              <img src={require("../../images/about1.jpg")} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default About;
